@import 'styles/_theme';
@import 'styles/_misc';
@import 'styles/_table';
@import 'styles/_form';
@import 'styles/_breadcrumb';
@import 'styles/_icons';

body,
div {
  color: $colorText;
}

app-step1-profile-check-form,
app-step1-profile-check-form-sdv {
  #genderField {
    .mat-form-field-invalid {
      margin-bottom: 1rem;
    }
  }
}

.form-page-component {
  app-user-form.form-component {
    app-parental-address-form {
      width: 100%;
      .form-content {
        padding: 0;
        .form-block {
          flex-basis: unset;
        }
      }
    }
  }
}

app-step4a-consent-proctoring-modal {
  app-form-field-checkbox {
    .mat-checkbox {
      margin-left: 1rem;
    }
    .mat-checkbox-layout {
      white-space: unset;
    }
    .mat-checkbox-label {
      font-style: italic;
    }
  }
}

app-step5-pricing-sdv {
  app-form-field-checkbox {
    .mat-checkbox-inner-container {
      margin-top: 6px;
    }
    .mat-checkbox-label {
      font-size: small;
      font-style: italic;
      white-space: break-spaces;
    }
  }
}

.mat-select-panel.max-height-unset {
  max-height: unset;
}
