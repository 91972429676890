.form-page-component {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .form-component {
    position: relative;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    .form-content {
      flex: 1;
      overflow-y: auto;
      gap: 2rem;
      padding: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > .form-block {
        flex: 1;
        flex-basis: 275px;
        display: flex;
        flex-direction: column;
        max-width: 450px;
        .mat-form-field {
          width: 100%;
        }
      }
    }

    .disabled {
      color: rgba(0, 0, 0, 0.38);

      .mat-form-field-flex {
        background-color: rgba(0, 0, 0, 0.02);
        pointer-events: none;
      }

      input {
        cursor: default;
      }

      mat-select .mat-select-arrow-wrapper {
        visibility: hidden;
      }

      .mat-form-field-underline {
        display: none;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      gap: 16px;
      padding: 16px;
    }
  }
}
