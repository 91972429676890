@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $primary-contrast: mat.get-color-from-palette(
    $primary-palette,
    A100-contrast
  );

  app-default-layout {
    .mat-toolbar {
      color: mat.get-color-from-palette(
        $primary-palette,
        'A700'
      );
      background-color: mat.get-color-from-palette(
        $primary-palette,
        'A100'
      );
    }

    .sdv-theme & mat-card-actions button:enabled {
      color: mat.get-color-from-palette(
        $primary-palette,
        'A100'
      );
      font-weight: 700;
      text-shadow: 1px 1px 0 $primary-contrast,
        -1px 1px 0 $primary-contrast,
        -1px -1px 0 $primary-contrast,
        1px -1px 0 $primary-contrast;
    }
  }
}
