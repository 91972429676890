@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  app-form-field-checkbox-terms-of-service {
    mat-checkbox {
      &.label-with-link {
        .mat-checkbox-layout {
          .mat-checkbox-label {
            a {
              color: mat.get-color-from-palette(
                $primary-palette,
                500
              );
              &:hover {
                color: mat.get-color-from-palette(
                  $primary-palette,
                  900
                );
              }
            }
          }
        }
      }
    }
  }
}
