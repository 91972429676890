@use '@angular/material' as mat;
@use '../app/pages/landing-page/landing-page-theme.component'
  as landing-page;
@use './breadcrumb' as breadcrumb;
@use '../app/components/footer/footer-theme.component' as
  footer;
@use '../app/layouts/plain-layout/plain-layout.component-theme'
  as plain-layout;
@use '../app/layouts/default-layout/default-layout-theme.component'
  as default-layout;
@use '../app/components/header-navbar/header-navbar.component-theme'
  as header-navbar;
@use '../app/components/booking/form-field-checkbox-gdpr/form-field-checkbox-gdpr-theme.component'
  as checkbox-gdpr;
@use '../app/components/booking/form-field-checkbox-terms-of-service/form-field-checkbox-terms-of-service-theme.component'
  as checkbox-terms-of-service;
@use '../app/components/booking/test-time-card/test-time-card-theme.component'
  as test-time-card;
@use '../app/components/dialogs/cancel-booking-dialog/cancel-booking-dialog-theme.component'
  as cancel-booking-dialog;
@use '../app/components/dialogs/change-booking-dialog/change-booking-dialog-theme.component'
  as change-booking-dialog;
@use '../app/components/dialogs/change-payment-status-dialog/change-payment-status-dialog-theme.component'
  as change-payment-status-dialog;
@use '../app/components/dialogs/invite-dialog/invite-dialog-theme.component'
  as invite-dialog;
@use '../app/components/tables/exam-event-statistics-table/exam-event-statistics-table-theme.component'
  as exam-event-statistics-table;

@import '_colors';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color

$boti-frontend-primary: mat.define-palette(
  $base-palette: $boti-color-palette-primary,
  $text: A700,
);

$boti-frontend-accent: mat.define-palette(
  $boti-color-palette-accent,
  $text: A700
);

$boti-frontend-warn: mat.define-palette(
  $boti-color-palette-warn,
  $text: 900
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$boti-frontend-theme: mat.define-light-theme(
  $primary: (
    color: (
      primary: $boti-frontend-primary,
      accent: $boti-frontend-accent,
      warn: $boti-frontend-warn,
    ),
  ),
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $headline:
    mat.define-typography-level(
      $font-size: 24px,
      $line-height: 32px,
      $font-weight: 400,
      $font-family: 'Montserrat, sans-serif',
    ),
);

// Override the typography in the core CSS.
@include mat.core($custom-typography);

/* You can add global styles to this file, and also import other style files */
@include mat.all-component-themes($boti-frontend-theme);

@include breadcrumb.color($boti-frontend-theme);
@include cancel-booking-dialog.color($boti-frontend-theme);
@include change-booking-dialog.color($boti-frontend-theme);
@include change-payment-status-dialog.color(
  $boti-frontend-theme
);
@include checkbox-gdpr.color($boti-frontend-theme);
@include checkbox-terms-of-service.color(
  $boti-frontend-theme
);
@include default-layout.color($boti-frontend-theme);
@include exam-event-statistics-table.color(
  $boti-frontend-theme
);
@include footer.color($boti-frontend-theme);
@include header-navbar.color($boti-frontend-theme);
@include invite-dialog.color($boti-frontend-theme);
@include landing-page.color($boti-frontend-theme);
@include plain-layout.color($boti-frontend-theme);
@include test-time-card.color($boti-frontend-theme);

//SdV Paletten
$sdv-frontend-primary: mat.define-palette(
  $boti-sdv-color-palette-primary
);

$sdv-frontend-accent: mat.define-palette(
  $boti-sdv-color-palette-accent
);

$sdv-frontend-dark-gray: mat.define-palette(
  $boti-sdv-color-palette-dark-gray
);

$sdv-theme: mat.define-light-theme(
  (
    color: (
      primary: $sdv-frontend-primary,
      accent: $sdv-frontend-accent,
    ),
  )
);

$sdv-dark-gray-theme: mat.define-light-theme(
  (
    color: (
      primary: $sdv-frontend-primary,
      accent: $sdv-frontend-accent,
    ),
  )
);

.sdv-theme {
  @include mat.all-component-colors($sdv-theme);
  @include mat.button-color($sdv-dark-gray-theme);

  @include breadcrumb.color($sdv-dark-gray-theme);
  @include cancel-booking-dialog.color($sdv-theme);
  @include change-booking-dialog.color($sdv-theme);
  @include change-payment-status-dialog.color($sdv-theme);
  @include checkbox-gdpr.color($sdv-theme);
  @include checkbox-terms-of-service.color($sdv-theme);
  @include default-layout.color($sdv-dark-gray-theme);
  @include exam-event-statistics-table.color($sdv-theme);
  @include footer.color($sdv-theme);
  @include header-navbar.color($sdv-theme);
  @include invite-dialog.color($sdv-theme);
  @include landing-page.color($sdv-theme);
  @include plain-layout.color($sdv-theme);
  @include test-time-card.color($sdv-theme);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, 'Helvetica Neue', sans-serif;
}

// from here is manual adjustments to this theme
.mat-drawer-container {
  background-color: transparent;
}
