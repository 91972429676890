@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .plain-footer {
    .link-container {
      a.active-link {
        color: mat.get-color-from-palette(
          $primary-palette,
          300
        ) !important;
      }
    }
  }
}
