@use 'sass:map';
@use '@angular/material' as mat;
@import '_colors';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $primary-contrast: mat.get-color-from-palette(
    $primary-palette,
    darker-contrast
  );
  $primary-dark: mat.get-color-from-palette(
    $primary-palette,
    A100-contrast
  );
  $accent-palette: map.get($color-config, 'accent');
  $accent-contrast: mat.get-color-from-palette(
    $accent-palette,
    A400-contrast
  );

  app-header-navbar {
    .nav-item {
      color: $primary-contrast;
      text-shadow: 1px 1px 0 $colorPureBlack,
        -1px 1px 0 $colorPureBlack,
        -1px -1px 0 $colorPureBlack,
        1px -1px 0 $colorPureBlack;
      font-weight: 700;

      &:hover {
        color: mat.get-color-from-palette(
          $primary-palette,
          200
        );
      }
    }

    .active-link {
      &:hover {
        color: $primary-contrast;
      }
    }
  }

  .sdv-theme app-header-navbar .nav-item {
    text-shadow: 0 0 1px $accent-contrast;
  }
}
