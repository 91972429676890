@use '@angular/material' as mat;

$testColor: #33ff22;

// colors from document
$colorPrime: rgb(0, 148, 199);
$colorSecondary: rgb(0, 43, 56);
$colorTertiary: #82cce0;
$colorQuaternary: #cce8f0;

$colorWhite: rgb(250, 250, 250);
$colorPureWhite: #ffffff;
$colorBlack: rgb(33, 33, 33);
$colorPureBlack: rgb(0, 0, 0);
$colorGrey: rgb(61, 61, 61);
$colorRed: #ad0d0d;
$colorYellow: #ffc258;
$colorGreen: rgb(94, 163, 92);

$colorContrast01: #6da29e;
$colorContrast02: #c8dfba;

$colorText: $colorBlack;

// used definitions, its a mapping from the numbers to readable names for use
$colorBackground: $colorPrime;
$colorHeaderBackground: $colorWhite;
$colorFooterBackground: $colorWhite;

// Generated with http://mcg.mbitson.com/#!?mcgpalette0=%230094c7
$boti-color-palette-primary: (
  50: #e0f2f8,
  100: #b3dfee,
  200: #80cae3,
  300: #4db4d8,
  400: #26a4cf,
  500: $colorPrime,
  600: #008cc1,
  700: #0081ba,
  800: #0077b3,
  900: #0065a6,
  A100: $colorWhite,
  A200: $colorQuaternary,
  A400: $colorGrey,
  A700: $colorGrey,
  contrast: (
    50: $colorBlack,
    100: $colorBlack,
    200: $colorBlack,
    300: $colorBlack,
    400: $colorBlack,
    500: $colorWhite,
    600: $colorWhite,
    700: $colorWhite,
    800: $colorWhite,
    900: $colorWhite,
    A100: $colorGrey,
    A200: $colorGrey,
    A400: $colorWhite,
    A700: $colorWhite,
  ),
);

// Generated with http://mcg.mbitson.com/#!?mcgpalette0=%23002b38
$boti-color-palette-accent: (
  50: #e0e6e7,
  100: #b3bfc3,
  200: #80959c,
  300: #4d6b74,
  400: #264b56,
  500: $colorSecondary,
  600: #002632,
  700: #00202b,
  800: #001a24,
  900: #001017,
  A100: #57b9ff,
  A200: #24a4ff,
  A400: #008cf0,
  A700: #007dd6,
  contrast: (
    50: $colorBlack,
    100: $colorBlack,
    200: $colorBlack,
    300: $colorWhite,
    400: $colorWhite,
    500: $colorWhite,
    600: $colorWhite,
    700: $colorWhite,
    800: $colorWhite,
    900: $colorWhite,
    A100: $colorBlack,
    A200: $colorBlack,
    A400: $colorWhite,
    A700: $colorWhite,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$boti-color-palette-warn: (
  50: #feeaec,
  100: #fdcacd,
  200: #ea9592,
  300: #de6c69,
  400: #e64b43,
  500: #e83a27,
  600: #da2f27,
  700: #c92421,
  800: #bc1d1a,
  900: $colorRed,
  A100: #ffffff,
  A200: #ffdede,
  A400: #ffacab,
  A700: #ff9291,
  contrast: (
    50: $colorBlack,
    100: $colorBlack,
    200: $colorBlack,
    300: $colorBlack,
    400: $colorBlack,
    500: $colorWhite,
    600: $colorWhite,
    700: $colorWhite,
    800: $colorWhite,
    900: $colorWhite,
    A100: $colorGrey,
    A200: $colorGrey,
    A400: $colorGrey,
    A700: $colorGrey,
  ),
);

$boti-color-primary: mat.get-color-from-palette(
  $boti-color-palette-primary,
  600
);

$boti-color-accent: mat.get-color-from-palette(
  $boti-color-palette-accent,
  900
);

$boti-color-warn: mat.get-color-from-palette(
  $boti-color-palette-warn,
  900
);

// SdV colors
$sdv-primary: #ed8601;
$sdv-secondary: #1391a6;
$sdv-accent: #a0a1a1;
$sdv-dark-grey: #333;

$boti-sdv-color-palette-primary: (
  50: #fdf0e1,
  100: #fadbb3,
  200: #f6c380,
  300: #f2aa4d,
  400: #f09827,
  500: $sdv-primary,
  600: #eb7e01,
  700: #e87301,
  800: #e56901,
  900: #e05600,
  A100: #ffffff,
  A200: #ffe2d4,
  A400: #ffbfa1,
  A700: #ffae88,
  contrast: (
    50: $colorPureBlack,
    100: $colorPureBlack,
    200: $colorPureBlack,
    300: $colorPureBlack,
    400: $colorPureBlack,
    500: $colorPureWhite,
    600: $colorPureWhite,
    700: $colorPureWhite,
    800: $colorPureWhite,
    900: $colorPureWhite,
    A100: $colorPureBlack,
    A200: $colorPureBlack,
    A400: $colorPureBlack,
    A700: $colorPureBlack,
  ),
);

$boti-sdv-color-palette-accent: (
  50: #e3f2f4,
  100: #b8dee4,
  200: #89c8d3,
  300: #5ab2c1,
  400: #36a2b3,
  500: $sdv-secondary,
  600: #11899e,
  700: #0e7e95,
  800: #0b748b,
  900: #06627b,
  A100: #a9eaff,
  A200: #76deff,
  A400: #43d1ff,
  A700: #2acbff,
  contrast: (
    50: $colorPureBlack,
    100: $colorPureBlack,
    200: $colorPureBlack,
    300: $colorPureBlack,
    400: $colorPureBlack,
    500: $colorPureWhite,
    600: $colorPureWhite,
    700: $colorPureWhite,
    800: $colorPureWhite,
    900: $colorPureWhite,
    A100: $colorPureBlack,
    A200: $colorPureBlack,
    A400: $colorPureBlack,
    A700: $colorPureBlack,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$boti-sdv-color-palette-dark-gray: (
  50: #e7e7e7,
  100: #c2c2c2,
  200: #999999,
  300: #707070,
  400: #525252,
  500: $sdv-dark-grey,
  600: #2e2e2e,
  700: #272727,
  800: #202020,
  900: #141414,
  A100: #ef6e6e,
  A200: #ea4040,
  A400: #f60000,
  A700: #dd0000,
  contrast: (
    50: $colorPureBlack,
    100: $colorPureBlack,
    200: $colorPureBlack,
    300: $colorPureWhite,
    400: $colorPureWhite,
    500: $colorPureWhite,
    600: $colorPureWhite,
    700: $colorPureWhite,
    800: $colorPureWhite,
    900: $colorPureWhite,
    A100: $colorPureBlack,
    A200: $colorPureWhite,
    A400: $colorPureWhite,
    A700: $colorPureWhite,
  ),
);

$sdv-color-primary: mat.get-color-from-palette(
  $boti-sdv-color-palette-primary,
  500
);

$sdv-color-accent: mat.get-color-from-palette(
  $boti-sdv-color-palette-accent,
  500
);

$sdv-dark-gray: mat.get-color-from-palette(
  $boti-sdv-color-palette-dark-gray,
  500
);
