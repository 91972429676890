@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  $primary: mat.get-color-from-palette(
    $primary-palette,
    500
  );
  $primary-contrast: mat.get-color-from-palette(
    $primary-palette,
    default-contrast
  );

  // Get the accent color palette from the color-config
  $accent-palette: map.get($color-config, 'accent');
  $accent-contrast: mat.get-color-from-palette(
    $accent-palette,
    A400-contrast
  );
  $accent: mat.get-color-from-palette($accent-palette, 500);

  app-landing-page {
    .content-container {
      h1 {
        color: $primary-contrast;
      }
    }

    .password-link-container {
      > a {
        color: $accent;
        &:hover {
          color: $primary !important;
        }
      }
    }

    .content-container h1 {
      text-shadow: 0 0 2px $accent-contrast;
    }
  }

  .sdv-theme app-landing-page .content-container h1 {
    text-shadow: 0 0 1px $accent-contrast;
  }
}
