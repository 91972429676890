.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-jc-space-between {
  justify-content: space-between;
}

.flex-ai-center {
  align-items: center;
}

.mdi {
  font-size: 1.5rem;
}

.debug {
  background-color: #eeeeee;
  border: solid 1px #cccccc;
  margin: 3px;
  padding: 3px;
}

.ripple {
  background-position: center;
  transition: background 0.5s;
}

.ripple:active {
  background-color: #ccc;
  background-size: 100%;
  transition: background 0s;
}

.clickable {
  cursor: pointer;
}

.hover-light:hover {
  background-color: #eeeeee;
}

.hover-outline:hover {
  box-shadow: inset 0 0 5px #cccccc;
}

.mat-button-base {
  text-transform: uppercase;
}

.default-page-component {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .mat-toolbar {
    flex-shrink: 0;
  }
  .page-content-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    gap: 1rem;
    padding: 0 1rem;
    > .page-block {
      flex: 1;
      flex-basis: 275px;
      display: flex;
      flex-direction: column;
      max-width: 450px;
      width: 100%;
      margin-bottom: 1.5rem;
      .full {
        width: 100%;
      }
    }
  }
}

.mat-select-panel.university-select-panel,
.mat-select-panel.language-select-panel {
  max-width: unset;
}

.width100 {
  width: 100%;
}

.spacerBottom {
  margin-bottom: 1em;
}

.tooltip {
  font-size: 0.9rem;
  background-color: $colorSecondary;
}

@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

.container-content {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-content {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-content {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-content {
    max-width: 1320px;
  }
}

mat-dialog-container {
  position: relative;
}

.snack-bar-large {
  max-width: 80vw !important;
}
