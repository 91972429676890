@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $primary-contrast: mat.get-color-from-palette(
    $primary-palette,
    A100-contrast
  );

  app-test-time-card {
    .card-input {
      color: mat.get-color-from-palette(
        $primary-palette,
        A700
      );

      .sdv-theme & * {
        color: $primary-contrast;
      }
    }

    .card-input-element:checked + .card-input {
      background-color: mat.get-color-from-palette(
        $primary-palette,
        A200
      );
    }
  }
}
