.table-page-component {
  height: 100%;
  display: flex;
  flex-direction: column;

  .entity-table-filter {
    flex-shrink: 0;
  }

  .entity-table-component {
    position: relative;
    min-height: 0;
    overflow-y: auto;
    table {
      width: 100%;
      > thead {
        > tr > th {
          position: sticky;
          top: 0;
          background-color: #ffffff;
        }
      }
    }
  }

  .entity-table-paginator {
    flex-shrink: 0;
  }
}
