@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  $primary: mat.get-color-from-palette(
    $primary-palette,
    500
  );
  $primary-contrast: mat.get-color-from-palette(
    $primary-palette,
    default-contrast
  );

  app-change-booking-dialog {
    .mat-step-header {
      .mat-step-icon {
        border: 1px solid $primary;
        color: $primary;
      }
    }

    .mat-step-header {
      .mat-step-icon.mat-step-icon-selected {
        background-color: $primary;
        color: $primary-contrast;
      }
    }

    .icon {
      color: $primary;
    }
  }
}
