@use 'sass:map';
@use '@angular/material' as mat;
@import '_colors';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');
  $accent-contrast: mat.get-color-from-palette(
    $accent-palette,
    darker-contrast
  );

  xng-breadcrumb {
    .xng-breadcrumb-root {
      background-color: transparent;

      .xng-breadcrumb-list {
        color: mat.get-color-from-palette(
          $accent-palette,
          900
        );
      }
    }
  }
}

xng-breadcrumb {
  .xng-breadcrumb-root {
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    display: inline-block;
    border-radius: 4px;

    .mat-icon {
      height: 1.2rem;
      width: 1.2rem;
    }

    .material-icons {
      font-size: 1.2rem;
    }
  }
}
